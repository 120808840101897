//
// Aside dark theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.aside {
	// background-color: get($layout-themes, light);
	// background-color: #f5f5f5;
	background-color: #ffffff;
	box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.08);

	// Aside Menu
	.aside-menu {
		// Scrollbar
		@include perfect-scrollbar-theme(#ffffff);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, light);
